:root {
	--green: #009933;
	--gold: #FFCC00;
	--white: #FAFAFA;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	display: flex;
	width: 100%;
	height: 100%;
	padding-top: 40px;
	justify-content: center;
	margin: 0;
	font-size: 20px;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--green);
	color: var(--gold);
	transition: background-color 5s linear;
}

.animated-logo {
	display: flex;
	justify-content: space-around;
	color: var(--gold);
}
.animated-logo span {
	font-size: 160px;
	line-height: 1em;
}

.intro {
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	text-shadow: 1px 1px 5px rgba(0,0,0,0.75);
	color: var(--white);
}
.intro h1 {
	margin: 20px auto 0;
	font-size: 60px;
	line-height: 1em;
}
.intro h3 {
	font-size: 32px;
}
.intro h3 small {
	display: block;
	font-size: 20px;
}
.intro h2 label {
	display: block;
	font-size: 24px;
}

.el1,
.el2,
.el3 {
	transition: color 2.5s linear;
}

main {
	text-align: center;
}
main p {
	text-shadow: 1px 1px 5px rgba(0,0,0,0.75);
}
main .MuiButton-containedPrimary {
	font-weight: 700;
	text-transform: uppercase;
	background-color: var(--green);
	color: var(--white);
}
main .MuiButton-containedPrimary:hover {
	background-color: var(--gold);
	color: var(--green);
}

main .mint-remaining {
	display: none !important;
}

.socials {
	position: absolute;
	top: 5px;
	right: 5px;
}

.marketplaces-container {
	margin-bottom: 20px;
	text-align: center;
}
.marketplaces-container .title {
	margin-top: 0px;
	margin-bottom: 7px;
	text-transform: uppercase;
}
.marketplaces {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.marketplaces .marketplace-digitaleyes {
	width: 120px;
}
